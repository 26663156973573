import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, lazy } from 'react'; // Import React and Suspense
import RootLayout from './layouts/RootLayout';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './components/NotFound';
import { isAuthenticated } from './utils/auth';

// Lazy load components
const LookupTable = lazy(() => import('./components/LookupTable'));
const AccountsTable = lazy(() => import('./components/AccountsTable'));
const DailyEntryTable = lazy(() => import('./components/DailyEntryTable'));
const EntityTable = lazy(() => import('./components/EntityTable'));
const Login = lazy(() => import('./components/Login'));
const UserTable = lazy(() => import('./components/UserTable'));
const SecuritysetupTable = lazy(
  () => import('./components/SecuritysetupTable.js')
);
const TenantTable = lazy(() => import('./components/TenantTable'));
const Home = lazy(() => import('./components/Home'));
const Reports = lazy(() => import('./components/Reports.js'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      {/* Redirect root ("/") to /login if not authenticated */}
      <Route
        index
        element={
          isAuthenticated() ? <Navigate to="/home" /> : <Navigate to="/login" />
        }
      />

      {/* Protected Routes */}
      <Route path="home" element={<ProtectedRoute element={<Home />} />} />
      <Route
        path="dailyentry"
        element={<ProtectedRoute element={<DailyEntryTable />} />}
      />
      <Route
        path="accounts"
        element={<ProtectedRoute element={<AccountsTable />} />}
      />
      <Route
        path="accountgroup"
        element={
          <ProtectedRoute
            element={
              <LookupTable
                strlookuptype="AccountGroup"
                strdescription="Account Group"
                key={window.location.pathname}
              />
            }
          />
        }
      />
      <Route
        path="taxgroup"
        element={
          <ProtectedRoute
            element={
              <LookupTable
                strlookuptype="TaxGroup"
                strdescription="Transaction Group"
                key={window.location.pathname}
              />
            }
          />
        }
      />
      <Route path="user" element={<ProtectedRoute element={<UserTable />} />} />
      <Route
        path="securitysetup"
        element={<ProtectedRoute element={<SecuritysetupTable />} />}
      />
      <Route
        path="securitygroup"
        element={
          <ProtectedRoute
            element={
              <LookupTable
                strlookuptype="SecurityGroup"
                strdescription="Security Group"
                key={window.location.pathname}
              />
            }
          />
        }
      />
      <Route
        path="entities"
        element={<ProtectedRoute element={<EntityTable />} />}
      />
      <Route
        path="tenant"
        element={<ProtectedRoute element={<TenantTable />} />}
      />
      <Route
        path="reports"
        element={<ProtectedRoute element={<Reports />} />}
      />
      {/* Public route */}
      <Route path="login" element={<Login />} />
      {/* Catch-all route for 404 */}
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return (
    <Suspense
      fallback={
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div
            className="spinner-border text-primary me-2"
            style={{ width: '3rem', height: '3rem' }}
            role="status"
          ></div>
          <span>Loading...</span>
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
