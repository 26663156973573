import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JavaScript
import '../src/index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import { InternetStatusProvider } from './context/InternetStatusContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <InternetStatusProvider>
      <App />
    </InternetStatusProvider>
  </React.StrictMode>
  /*<React.StrictMode>
    <App />
  </React.StrictMode>*/
);
