// client/src/context/InternetStatusContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';

// Create Context for internet status
const InternetStatusContext = createContext();

// Custom hook to use the internet status context
export const useInternetStatus = () => {
  return useContext(InternetStatusContext);
};

// Context Provider Component
export const InternetStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Initialize with current online status

  useEffect(() => {
    // Handler for online event
    const handleOnline = () => setIsOnline(true);
    // Handler for offline event
    const handleOffline = () => setIsOnline(false);

    // Listen to online and offline events
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <InternetStatusContext.Provider value={isOnline}>
      {children}
    </InternetStatusContext.Provider>
  );
};
