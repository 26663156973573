import React from 'react';

const NotFound = () => {
  return (
    <div>
      <style>
        {`
                @import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");

                :root {
                    --bg: #313942;
                    --ghost: #528cce;
                    --heading: #e7ebf2;
                }

                * {
                    box-sizing: border-box;
                }

                body {
                    background-color: var(--bg);
                    font-family: 'Montserrat', sans-serif;
                    margin: 0; /* Reset margin */
                }

                main {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    height: 100vh;
                    justify-content: center;
                    text-align: center;
                }

                h1 {
                    color: var(--heading);
                    font-size: 12.5rem;
                    letter-spacing: .10em;
                    margin: .025em 0;
                    text-shadow: .05em .05em 0 rgba(0,0,0,.25);
                    white-space: nowrap;
                }

                @media(max-width: 30rem) {
                    h1 {
                        font-size: 8.5rem;
                    }
                }

                h1 > span {
                    animation: spooky 2s alternate infinite linear;
                    color: var(--ghost);
                    display: inline-block;
                }

                h2 {
                    color: var(--heading);
                    margin-bottom: .40em;
                }

                p {
                    color: #ccc;
                    margin-top: 0;
                }
                `}
      </style>
      <main>
        <h1>404</h1>
        <h2>Error: 404 page not found</h2>
        <p>Sorry, the page you're looking for cannot be accessed</p>
        <a href="/">Go to Home</a>
      </main>
    </div>
  );
};

export default NotFound;
