// client/src/layouts/RootLayout.jsx
import { Outlet, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FaUser, FaSignOutAlt, FaSignInAlt, FaWifi } from 'react-icons/fa';
import logo from '../assets/images/SMEFlow_logo.png';
import { useInternetStatus } from '../context/InternetStatusContext';

export default function RootLayout() {
  const [loggedIn, setLoggedIn] = useState(false);
  const isOnline = useInternetStatus();
  const [showAlert, setShowAlert] = useState(false);

  //#region *** DATA ***
  useEffect(() => {
    checkAuth();
    window.addEventListener('storage', checkAuth);
    return () => window.removeEventListener('storage', checkAuth);
  }, []);
  //#endregion

  //#region *** ACTION ***
  useEffect(() => {
    if (!isOnline) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000); // Hide the alert after 3 seconds
    }
  }, [isOnline]);
  const checkAuth = () => {
    const isLoggedIn = sessionStorage.getItem('accessToken') !== null;
    setLoggedIn(isLoggedIn);
  };
  const handleLogout = () => {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    setLoggedIn(false); // Update state after logging out
    window.location.href = '/login'; // Redirect to login
  };
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `/help/MSMEFlow.chm`;
    link.download = 'MSMEFlow.chm';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleNavItemClick = () => {
    const hamburgerButton = document.getElementById('hamburgerButton');
    const navbarCollapse = document.getElementById('navbarTogglerDemo01');

    if (navbarCollapse.classList.contains('show')) {
      // Trigger click on the hamburger button to collapse the menu
      hamburgerButton.click();
    }
  };
  //#endregion
  return (
    <div className="root-layout">
      <nav className="navbar navbar-expand-lg p-0">
        <div className="container-fluid p-2">
          <NavLink
            className="navbar-brand d-flex align-items-center"
            to="/home"
            activeClassName="active"
          >
            <img
              src={logo}
              alt="SMEFlow Logo"
              width="50"
              height="50"
              className="me-2"
            />
            <span>
              MSME<span style={{ color: 'Red' }}>Flow </span>
            </span>
          </NavLink>{' '}
          {/* Branding */}
          {isOnline ? (
            <span style={{ color: 'lightgreen', fontSize: '7' }}>
              <FaWifi size={18} /> Online
            </span>
          ) : (
            <span style={{ color: 'red', fontSize: '7' }}>
              <FaWifi size={18} /> Offline
            </span>
          )}
          <button
            id="hamburgerButton"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>{' '}
          {/* Hamburger */}
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {loggedIn ? (
                <>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/home"
                      activeClassName="active"
                      onClick={handleNavItemClick}
                    >
                      Home
                    </NavLink>
                  </li>{' '}
                  {/* Home */}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/dailyentry"
                      activeClassName="active"
                      onClick={handleNavItemClick}
                    >
                      Daily Entry
                    </NavLink>
                  </li>{' '}
                  {/* Daily Log */}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/accounts"
                      activeClassName="active"
                      onClick={handleNavItemClick}
                    >
                      Accounts
                    </NavLink>
                  </li>{' '}
                  {/* Account */}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/reports"
                      activeClassName="active"
                      onClick={handleNavItemClick}
                    >
                      Reports
                    </NavLink>
                  </li>{' '}
                  {/* Reports */}
                  <li className="nav-item dropdown">
                    <button
                      className="btn btn-link nav-link dropdown-toggle"
                      id="userDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Setup
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="userDropdown"
                    >
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/accountgroup"
                          activeClassName="active"
                          onClick={handleNavItemClick}
                        >
                          Account Group
                        </NavLink>
                      </li>{' '}
                      {/* Account Group */}
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/taxgroup"
                          activeClassName="active"
                          onClick={handleNavItemClick}
                        >
                          Transaction Group
                        </NavLink>
                      </li>{' '}
                      {/* Tax Group */}
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/entities"
                          activeClassName="active"
                          onClick={handleNavItemClick}
                        >
                          Entities
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/user"
                          activeClassName="active"
                          onClick={handleNavItemClick}
                        >
                          Users
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/securitysetup"
                          activeClassName="active"
                          onClick={handleNavItemClick}
                        >
                          Security Setup
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/SecurityGroup"
                          activeClassName="active"
                          onClick={handleNavItemClick}
                        >
                          Security Group
                        </NavLink>
                      </li>
                    </ul>
                  </li>{' '}
                  {/* Dropdown Setup */}
                  <li className="nav-item dropdown">
                    <button
                      className="btn btn-link nav-link dropdown-toggle"
                      id="userDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FaUser size={20} /*color="white"*/ />
                    </button>{' '}
                    {/* Down Arrow */}
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="userDropdown"
                    >
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            handleDownload();
                          }}
                        >
                          Download Help
                        </button>{' '}
                        {/* Logout */}
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            handleLogout();
                          }}
                        >
                          <FaSignOutAlt size={20} color="white" /> Logout
                        </button>{' '}
                        {/* Logout */}
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/login"
                    onClick={handleNavItemClick}
                  >
                    <FaSignInAlt size={20} /> Login
                  </NavLink>
                </li> /* Signin */
              )}
            </ul>
          </div>
        </div>
      </nav>
      <main>
        <Outlet />
      </main>
    </div>
  );
}
